<template>
  <v-app>
    <v-navigation-drawer
      persistent
      :mini-variant="environment.ui.miniVariant"
      :clipped="environment.ui.clipped"
      v-model="environment.ui.drawer"
      enable-resize-watcher
      fixed
      app
    >
      <v-list>
        <v-list-item
          value="true"
          v-for="(item, i) in itemsMenu"
          :key="i"
          @click="goToLink(item.url)"
        >
          <v-list-item-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list>
          <div v-if="userAccount && userAccount.suppliers.length > 0">
            <v-list-group value="true">
              <v-list-item slot="activator">
                <v-list-item-title>{{
                  $t("home.suppliers")
                }}</v-list-item-title>
              </v-list-item>
              <v-list class="py-0 pl-1">
                <v-list-group
                  value="true"
                  v-for="(item, i) in userAccount.suppliers"
                  :key="i"
                >
                  <v-list-item slot="activator">
                    <v-list-item-title>{{
                      item.companyName
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list class="py-0 pl-2">
                    <v-list-item
                      v-for="(division, j) in item.divisions"
                      :key="j"
                    >
                      <v-list-item-action>
                        <v-icon>mdi-domain</v-icon>
                      </v-list-item-action>
                      <v-list-item-content
                        style="cursor:pointer"
                        @click="
                          goToSupplier(item.companyGuid, division.divisionGuid)
                        "
                      >
                        <v-list-item-title>{{
                          division.divisionName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-group>
              </v-list>
            </v-list-group>
          </div>
        </v-list>

        <div v-if="userAccount && userAccount.customers.length > 0">
          <v-list-group prepend-icon value="true">
            <v-list-item slot="activator">
              <v-list-item-title>{{ $t("home.customers") }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="(item, i) in userAccount.customers"
              :key="i"
              value="true"
              @click="goToCustomer(item.companyGuid)"
            >
              <v-list-item-action>
                <v-icon>group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.companyName"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div v-if="userAccount">
          <v-divider></v-divider>
          <div style="height:20px"></div>

          <v-list-item value="true" @click="goToLink(logoutMenu.url)">
            <v-list-item-action>
              <v-icon v-html="logoutMenu.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="logoutMenu.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :clipped-left="environment.ui.clipped">
      <v-app-bar-nav-icon
        @click.stop="environment.ui.drawer = !environment.ui.drawer"
      />

      <v-toolbar-title v-text="environment.ui.title"></v-toolbar-title>

      <v-spacer></v-spacer>

      <div style="margin-right:30px">
        <span class="font-italic" style="font-size:16px;">{{
          getUserName()
        }}</span>
      </div>

      <div>
        <a @click="changeLocale('en')">EN</a>
        <a
          @click="changeLocale('it')"
          style="margin-left:10px;margin-right:20px"
          >IT</a
        >
      </div>

      <v-btn icon @click="reloadPage()">
        <v-icon title="Ricarica pagina">refresh</v-icon>
      </v-btn>
    </v-app-bar>

    <div class="text-center">
      <v-overlay :value="isWaiting">
        <v-progress-circular
          indeterminate
          size="64"
          color="orange"
        ></v-progress-circular>
      </v-overlay>
    </div>

    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>

    <!--<v-navigation-drawer
      temporary
      :right="right"
      v-model="rightDrawer"
      fixed
      app>
      <v-list>
        <v-list-item @click="right = !right">
          <v-list-item-action>
            <v-icon>compare_arrows</v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>-->

    <!--<v-footer fixed app>
      <v-card-text class="text-xs-left">&copy; 2018 <a href="https://www.gs-link.it" target="_blank">GS Link</a> - {{this.time.deviceTime | formatDateTime}}</v-card-text>
      <v-card-text class="text-xs-right"><span v-if="userAccount">{{userAccount.name}} - <router-link to="logout">Logout</router-link> - </span>Versione <span v-if="environment.application">{{environment.application.version}}</span></v-card-text>
    </v-footer>-->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { locale, loadMessages } from "devextreme/localization";
import itMessages from "devextreme/localization/messages/it.json";

var moment = require("moment");

export default {
  name: "App",
  data() {
    return {
      logoutMenu: {
        icon: "logout",
        title: "LOG OUT",
        url: "/logout",
      },

      itemsMenu: [
        {
          icon: "home",
          title: "HOME",
          url: "/",
        },
      ],

      time: {
        timer: null,
        deviceTime: moment(),
        timeOffset: null,
      },
    };
  },

  created: function() {
    var culture = "it";
    locale(culture);
    loadMessages(itMessages);
  },

  mounted: function() {
    this.time.timer = setInterval(this.updateTimerUI, 200);
  },

  computed: {
    ...mapState(["environment", "userAccount"]),

    isWaiting() {
      return this.$store.state.isWaiting;
    },
  },

  methods: {
    getUserName() {
      var token = this.parseJwt(localStorage.getItem("user-token"));

      if (token && token.sub) {
        return token.sub.toUpperCase();
      } else {
        return "";
      }
    },

    parseJwt(token) {
      if (!token) return "";

      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("user-locale", locale);
    },

    goToLink(url) {
      this.$router.push(url);
    },

    goToSupplier(supplierId, divisionId) {
      this.$router
        .replace({
          name: "supplierDashboard",
          params: { supplierId: supplierId, divisionId: divisionId },
        })
        .catch(() => {
          //console.debug(e);
        });
    },

    goToCustomer(supplierId) {
      this.$router
        .push({
          name: "customerDashboard",
          params: { id: supplierId },
        })
        .catch(() => {
          //console.debug(e);
        });
    },

    updateTimerUI: function() {
      var actualTime = moment();
      if (
        this.environment.application &&
        this.environment.application.timeOffset
      ) {
        actualTime.add(this.environment.application.timeOffset);
      }
      this.time.deviceTime = actualTime;
    },

    reloadPage: function() {
      this.$router.go();
    },
  },

  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(value).format("ddd DD/MM/YYYY HH:mm.ss");
      }
    },
  },
};
</script>
