<template>
  <v-container fluid mx-2>
    <v-layout row v-for="(plant, i) in data.plants" :key="i">
      <v-flex xs12>
        <v-toolbar color="grey" dark>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-toolbar-title style="margin-top: 20px;">{{ plant.plantName }}</v-toolbar-title>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="kanbanStatusCustomer"
                  item-text="statusDescription"
                  item-value="idKanbanStatus"
                  label="Stato cliente"
                  outlined
                  v-model="currentKanbanStatusCustomer"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="kanbanStatusSupplier"
                  item-text="statusDescription"
                  item-value="idKanbanStatus"
                  label="Stato fornitore"
                  outlined
                  v-model="currentKanbanStatusSupplier"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>

          <v-icon
            @click="moveToEditKanbanData(kanbanItem)"
            title="Modifica dati kanban"
            >mdi-grid</v-icon
          >
          <div style="width:20px" class="hidden-xs-only"></div>
          <v-icon @click="moveToPrintPage(kanbanItem)">printer</v-icon>

          <v-text-field
            single-line
            solo
            light
            v-model="searchText"
            prepend-icon="search"
            placeholder="Cerca articolo..."
          >
          </v-text-field>
        </v-toolbar>

        <v-list>
          <template>
            <div
              v-for="(kanbanItem, i) in filteredKanbanItems(plant.kanbanItems)"
              :key="i"
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="2">
                    <h3>
                      <a :name="kanbanItem.customerCode" style="color:black">{{
                        kanbanItem.customerCode
                      }}</a>
                    </h3>
                    <h4>{{ kanbanItem.customerDescription }}</h4>
                  </v-col>
                  <v-col cols="10">
                    <v-layout row align-center>
                      <v-col cols="1" style="text-align:center">
                        <div v-if="kanbanItem.beginIndex > 0">
                          <v-icon @click="movePrevPage(kanbanItem)"
                            >arrow_back_ios</v-icon
                          >
                        </div>
                      </v-col>

                      <v-col
                        cols="2"
                        v-for="(card, i) in pageOfCards(kanbanItem)"
                        :key="i"
                      >
                        <div
                          class="kanbanCardNew"
                          v-bind:style="{
                            backgroundColor: card.colourDescription,
                          }"
                        >
                          <v-row>
                            <v-col cols="4">
                              <h4>{{ card.kbUnits }}</h4>
                            </v-col>
                            <v-col cols="8">
                              <h4 style="text-align:right">
                                {{ card.kanbanId }}
                              </h4>
                            </v-col>
                          </v-row>
                          <v-row style="margin-top:0px">
                            <v-col cols="4" style="margin-top:0px;padding-top:0px">
                              <h4>{{ card.codeUnits }}</h4>
                            </v-col>
                            <v-col cols="8" style="margin-top:0px;padding-top:0px">
                              <h4 style="text-align:right">
                                {{ card.codeQty | formatNumber }}
                              </h4>
                            </v-col>
                          </v-row>

                          <!-- <v-row style="margin-top:15px">
                            <v-col
                              cols="12"
                              style="text-align:left;margin-left:10px"
                            >
                              <a
                                @click="showLabelModal(kanbanItem, card)"
                                target="_blank"
                                style="border:0px;color:transparent"
                              >
                                <v-icon
                                  style="width:20px;border:0px"
                                  title="Stampa etichetta"
                                  >printer</v-icon
                                >
                              </a>

                              <span style="margin-left:10px">
                                A5
                                <a
                                  v-bind:href="
                                    '/api/report/getPDFKanbanCardA5?kanbanGuid=' +
                                      card.kanbanGuid
                                  "
                                  target="_blank"
                                >
                                  <v-icon title="Etichetta formato A5"
                                    >label</v-icon
                                  >
                                </a>
                              </span>
                              <span style="margin-left:10px">
                                A6
                                <a
                                  v-bind:href="
                                    '/api/report/getPDFKanbanCardA6?kanbanGuid=' +
                                      card.kanbanGuid
                                  "
                                  target="_blank"
                                >
                                  <v-icon title="Etichetta formato A6"
                                    >label</v-icon
                                  >
                                </a>
                              </span>
                            </v-col>
                          </v-row> -->

                          <v-row style="margin-top:0px">
                            <v-col
                              cols="12"
                              style="text-align:right;margin-right:10px;margin-top:0px;padding-top:0px"
                            >
                              <span>{{
                                card.kanbanDateModif | formatDate
                              }}</span>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>

                      <v-col cols="1" style="text-align:center;">
                        <div
                          v-if="
                            kanbanItem.beginIndex + maxCardsPerRow <
                              kanbanItem.cardItems.length
                          "
                        >
                          <v-icon @click="moveNextPage(kanbanItem)"
                            >arrow_forward_ios</v-icon
                          >
                        </div>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </template>
        </v-list>
      </v-flex>

      <v-dialog v-model="kanbanLabel.showDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Stampa etichetta Kanban</v-card-title>

          <v-card-text>
            Stampa l'etichetta Kanban per l'articolo
            <strong>{{
              kanbanLabel.kanbanItem && kanbanLabel.kanbanItem.customerCode
            }}</strong
            >, contenitore
            <strong>{{
              kanbanLabel.kanbanCard && kanbanLabel.kanbanCard.kanbanId
            }}</strong>
          </v-card-text>

          <v-card-text>
            <v-combobox
              v-model="kanbanLabel.currentPrinter"
              :items="printers"
              label="Scegli la stampante su cui stampare l'etichetta"
              item-text="key"
              item-value="value"
            ></v-combobox>
          </v-card-text>

          <v-card-text>
            <v-combobox
              v-model="kanbanLabel.copies"
              :items="copies"
              label="Numero di etichette da stampare"
            ></v-combobox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              :text="true"
              @click="printLabel(kanbanLabel)"
              >Stampa etichetta</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="kanbanLabel.showDymoDialog" max-width="400">
        <v-card>
          <v-card-title class="headline"
            >Stampa etichetta Kanban Dymo</v-card-title
          >

          <v-card-text>
            Stampa l'etichetta Dymo - Kanban per l'articolo
            <strong>{{
              kanbanLabel.kanbanItem && kanbanLabel.kanbanItem.customerCode
            }}</strong
            >, contenitore
            <strong>{{
              kanbanLabel.kanbanCard && kanbanLabel.kanbanCard.kanbanId
            }}</strong>
          </v-card-text>

          <v-card-text>
            <v-combobox
              v-model="kanbanLabel.currentDymoPrinter"
              :items="dymoPrinters"
              label="Scegli la stampante Dymo su cui stampare l'etichetta"
              item-text="name"
              item-value="name"
            ></v-combobox>
          </v-card-text>

          <v-card-text>
            <v-combobox
              v-model="kanbanLabel.copies"
              :items="copies"
              label="Numero di etichette da stampare"
            ></v-combobox>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              :text="true"
              @click="printDymoLabel(kanbanLabel)"
              >Stampa etichetta Dymo</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
var numeral = require("numeral");
var moment = require("moment");
import { mapState } from "vuex";
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "SupplierDashboard",

  props: {
    id: String,
    msg: String,
  },

  data: function() {
    return {
      data: {},
      kanbanStatusCustomer: [],
      kanbanStatusSupplier: [],
      currentKanbanStatusCustomer: null,
      currentKanbanStatusSupplier: null,
      maxCardsPerRow: 5,

      kanbanLabel: {
        showDialog: false,
        showDymoDialog: false,
        kanbanItem: null,
        kanbanCard: null,
        currentPrinter: null,
        currentDymoPrinter: null,
        copies: 1,
      },

      printers: [],
      dymoPrinters: [],

      copies: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

      searchText: "",
    };
  },

  mounted: function() {
    const token = localStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.environment.ui.title = this.$t("supplierDashboard.title");

    this.loadKanbanStatus();
    this.loadDashboard();
    this.loadPrintersAndLabels();

    //Ritardo il caricamento delle stampanti Dymo perchè è molto lento e mi blocca la pagina
    setTimeout(() => {
      this.loadDymoPrintersAndLabels();
    }, 2000);

    //Se c'è un testo di ricerca di default lo abilita
    if (this.$route.query.initialSearch) {
      this.searchText = this.$route.query.initialSearch;
    }
  },

  computed: {
    ...mapState(["environment", "userAccount"]),
  },

  methods: {
    filteredKanbanItems: function(items) {
      var ret = items;

      if(this.searchText && this.searchText.length>0){
        ret = ret.filter((item) => {
          return item.customerCode
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      }

      if(this.currentKanbanStatusCustomer!=null){
        if(this.currentKanbanStatusCustomer!=-1){
          ret = ret.filter((item) => {
            return item.cardItems.some( ci => ci.customerStatus == this.currentKanbanStatusCustomer);
          });
        }
      }

      if(this.currentKanbanStatusSupplier!=null){
        if(this.currentKanbanStatusSupplier!=-1){
          ret = ret.filter((item) => {
            return item.cardItems.some( ci => ci.supplierStatus == this.currentKanbanStatusSupplier);
          });
        }
      }

      return ret;
    },

    pageOfCards(kanbanItem) {
      if (!kanbanItem) return [];
      if (kanbanItem.beginIndex === undefined)
        this.$set(kanbanItem, "beginIndex", 0);

      return kanbanItem.cardItems.slice(
        kanbanItem.beginIndex,
        kanbanItem.beginIndex + this.maxCardsPerRow
      );
    },

    movePrevPage(kanbanItem) {
      if (kanbanItem.beginIndex === undefined) kanbanItem.beginIndex = 0;
      if (kanbanItem.beginIndex == 0) return;

      kanbanItem.beginIndex--;
    },

    moveNextPage(kanbanItem) {
      if (kanbanItem.beginIndex === undefined) kanbanItem.beginIndex = 0;
      if (
        kanbanItem.beginIndex + this.maxCardsPerRow >=
        kanbanItem.cardItems.length
      )
        return;

      kanbanItem.beginIndex++;
    },

    moveToPrintPage() {
      this.$router
        .replace({
          name: "printLabels",
          params: {
            supplierId: this.$route.params.supplierId,
            divisionId: this.$route.params.divisionId,
          },
        })
        .catch((e) => {
          console.debug(e);
        });
    },

    moveToEditKanbanData() {
      this.$router
        .replace({
          name: "editKanbanData",
          params: {
            supplierId: this.$route.params.supplierId,
            divisionId: this.$route.params.divisionId,
          },
        })
        .catch((e) => {
          console.debug(e);
        });
    },

    async loadKanbanStatus() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/kanbanStatusCustomer`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.kanbanStatusCustomer = ret.data.data;
            self.kanbanStatusCustomer.unshift({
              idKanbanStatus: -1,
              statusDescription: "[TUTTI]"
            });
            self.currentKanbanStatusCustomer = -1;
          }
        }

        ret = await axios({
          url: `/api/supplierKanban/kanbanStatusSupplier`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.kanbanStatusSupplier = ret.data.data;
            self.kanbanStatusSupplier.unshift({
              idKanbanStatus: -1,
              statusDescription: "[TUTTI]"
            });
            self.currentKanbanStatusSupplier = -1;
          }
        }
      } catch (e) {
        swal("ERROR", e);
      } finally {
        this.showLoader(false);
      }
    },

    async loadDashboard() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/dashboardData?supplierGuid=${this.$route.params.supplierId}&divisionGuid=${this.$route.params.divisionId}`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.data = ret.data.data;
          }
        }
      } catch (e) {
        swal("ERROR", e);
      } finally {
        this.showLoader(false);
      }
    },

    loadDymoPrintersAndLabels() {
      this.initDymo();
    },

    initDymo() {
      if (window.dymo.label.framework.init) {
        window.dymo.label.framework.init(this.onLoadDymo);
      } else {
        this.onLoadDymo();
      }
    },

    onLoadDymo() {
      let env = window.dymo.label.framework.checkEnvironment();

      if (
        !env.isBrowserSupported ||
        !env.isFrameworkInstalled ||
        !env.isWebServicePresent
      ) {
        return false;
      }

      let printers = window.dymo.label.framework.getPrinters();
      if (printers.length == 0) return false;
      this.dymoPrinters = printers;
    },

    async printDymoLabel(item) {
      if (this.kanbanLabel.kanbanItem === null) return;
      if (this.kanbanLabel.kanbanCard === null) return;
      if (!this.kanbanLabel.copies) return;
      if (!this.kanbanLabel.currentDymoPrinter) return;

      let response = await fetch("/labels/dymo/kanban.label", {
        mode: "no-cors",
      });
      if (!response) {
        console.debug("cant open label file");
        return;
      }

      let xml = await response.text();

      //Sostituisce le variabili
      xml = xml.replace("${data}", moment().format("DD/MM/YYYY"));
      xml = xml.replace(
        "${CustomerCode}",
        this.kanbanLabel.kanbanCard.customerCode
      );
      xml = xml.replace(
        "${CustomerDescription}",
        this.kanbanLabel.kanbanCard.customerDescription
      );
      xml = xml.replace("${KanbanId}", this.kanbanLabel.kanbanCard.kanbanId);
      xml = xml.replace("${CodeQty}", this.kanbanLabel.kanbanCard.codeQty);
      xml = xml.replace(
        "${url}",
        `http://ekanban.syncro-system.com/qrcode/${
          item.kanbanCard.kanbanGuid
        }?code=${this.kanbanLabel.kanbanItem.customerCode}&amp;ts=${Date.now()}`
      );

      let label = window.dymo.label.framework.openLabelXml(xml);

      if (!label.isValidLabel()) {
        console.debug("label file not valid");
        return;
      }

      console.debug(`printing on ${this.kanbanLabel.currentDymoPrinter.name}`);
      label.print(this.kanbanLabel.currentDymoPrinter.name);
    },

    loadPrintersAndLabels() {
      let self = this;

      axios({
        url: "/api/label/GetLabelPrintersFolderList",
        data: null,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.success) {
            self.printers = resp.data.data;

            let printer = localStorage.getItem("default-printer");
            if (printer !== null) {
              let found = this.printers.find((p) => {
                return p.key === printer;
              });

              if (found) {
                this.kanbanLabel.currentPrinter = found;
              } else if (this.printers.length > 0) {
                this.kanbanLabel.currentPrinter = this.printers[0];
              }
            }
          } else {
            swal("ERROR", resp.data.errorMessage);
          }
        })
        .catch((err) => {
          swal("ERROR", err);
        });
    },

    showLabelModal(kanbanItem, kanbanCard) {
      if (this.dymoPrinters.length > 0) {
        if (this.kanbanLabel.currentDymoPrinter == null) {
          this.kanbanLabel.currentDymoPrinter = this.dymoPrinters[0];
        }

        this.kanbanLabel.kanbanItem = kanbanItem;
        this.kanbanLabel.kanbanCard = kanbanCard;
        this.kanbanLabel.copies = 1;
        this.kanbanLabel.showDymoDialog = true;
      } else {
        if (this.kanbanLabel.currentPrinter === null) {
          if (this.printers.length > 0) {
            this.kanbanLabel.currentPrinter = this.printers[0];
          }
        }

        this.kanbanLabel.kanbanItem = kanbanItem;
        this.kanbanLabel.kanbanCard = kanbanCard;
        this.kanbanLabel.copies = 1;
        this.kanbanLabel.showDialog = true;
      }
    },

    printLabel(item) {
      if (this.kanbanLabel.kanbanItem === null) return;
      if (this.kanbanLabel.kanbanCard === null) return;
      if (!this.kanbanLabel.copies) return;
      if (!this.kanbanLabel.currentPrinter) return;

      localStorage.setItem(
        "default-printer",
        this.kanbanLabel.currentPrinter.key
      );

      var printData = {
        PrinterName: this.kanbanLabel.currentPrinter.key,
        LabelName: "kanban",
        Copies: this.kanbanLabel.copies,
      };

      printData.LabelVariables = new Object();
      printData.LabelVariables["DATA"] = moment().format("DD/MM/YYYY");
      printData.LabelVariables[
        "CustomerCode"
      ] = this.kanbanLabel.kanbanCard.customerCode;
      printData.LabelVariables[
        "CustomerDescription"
      ] = this.kanbanLabel.kanbanCard.customerDescription;
      printData.LabelVariables["KanbanId"] = String(
        this.kanbanLabel.kanbanCard.kanbanId
      );
      printData.LabelVariables["CodeQty"] = String(
        this.kanbanLabel.kanbanCard.codeQty
      );
      printData.LabelVariables[
        "url"
      ] = `http://ekanban.syncro-system.com/qrcode/${
        item.kanbanCard.kanbanGuid
      }?code=${this.kanbanLabel.kanbanItem.customerCode}&ts=${Date.now()}`;
      printData.LabelVariables["KbUnits"] = this.kanbanLabel.kanbanCard.kbUnits;
      printData.LabelVariables[
        "PlantDivision"
      ] = this.kanbanLabel.kanbanCard.plantDivision;

      console.debug(printData.LabelVariables["url"]);

      axios({
        url: "/api/label/PrintArticleLabel",
        data: printData,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.kanbanLabel.showDialog = false;
          } else {
            swal("ERROR", resp.data.errorMessage);
          }
        })
        .catch((err) => {
          swal("ERROR", err);
        });
    },

    showLoader(visible) {
      this.$store.state.isWaiting = visible;
    },
  },

  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YY HH:mm");
      }
    },

    formatNumber: function(value) {
      return numeral(value).format("0,0.[00]");
    },
  },
};
</script>

<style>
.kanbanCardNew {
  border: 1px solid #333;
  /*height:70px;*/
  margin: 2px;
  border-radius: 10px 2px 10px 2px;
  padding: 10px 5px 10px 5px;
}
</style>
