import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login";
import Logout from "./views/Logout";
import SupplierDashboard from "./views/Suppliers/SupplierDashboard";
import PrintLabels from "./views/Suppliers/PrintLabels";
import ReadQrCode from "./views/QrCode/ReadQrCode";
import KanbanLabel from "./views/Labels/KanbanLabel";
import store from "./store";
import SearchTestReport from "./views/Reports/SearchTestReport";
import TestDataGrid from "./views/Suppliers/TestDataGrid";
import EditKanbanData from "./views/Suppliers/EditKanbanData";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: "/", name: "home", component: Home },
    { path: "/login", name: "login", component: Login },
    { path: "/logout", name: "logout", component: Logout },
    {
      path: "/suppliers/:supplierId/:divisionId/dashboard",
      name: "supplierDashboard",
      component: SupplierDashboard,
    },
    {
      path: "/suppliers/test",
      name: "test",
      component: TestDataGrid,
    },
    {
      path: "/suppliers/:supplierId/:divisionId/printLabels",
      name: "printLabels",
      component: PrintLabels,
    },
    {
      path: "/suppliers/:supplierId/:divisionId/editKanbanData",
      name: "editKanbanData",
      component: EditKanbanData,
    },
    { path: "/qrcode/:q", name: "readQrCode", component: ReadQrCode },
    { path: "/labels/kanban/:q", name: "labelKanban", component: KanbanLabel },
    {
      path: "/reports/searchTestReport",
      name: "searchTestReport",
      component: SearchTestReport,
    },
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem('user');
  let loggedIn = store.getters.isAuthenticated;

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
