import "@babel/polyfill";
import Vue from "vue";
import "./plugins/axios";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import i18n from "./i18n";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//Si assicura che quando ricarico l'applicazione, se c'è un token nello storage lo re-imposta negli header
//di default di axios
const token = localStorage.getItem("user-token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  //Chiede anche i dati dell'utente
  store
    .dispatch("USER_REQUEST")
    .then(() => {
      //In caso di successo
    })
    .catch((err) => {
      if (err.response.status == 401) {
        //Si assicura di togliere il token
        localStorage.removeItem("user-token");
        store.state.token = "";
        store.state.userAccount = null;
        router.push("/login");
      }
    });
}

//Legge i valori di ambiente dell'applicazione
store.dispatch("ENVIRONMENT_APPLICATION");

var numeral = require("numeral");

//Per numeral
numeral.register("locale", "it", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  currency: {
    symbol: "€",
  },
});
numeral.locale("it");
