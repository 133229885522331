<template>
  <v-container fluid mx-2>
    <div>Test page</div>

    <DxButton text="Click me" @click="sayHelloWorld" />
  </v-container>
</template>

<script>
import DxButton from "devextreme-vue/button";

export default {
  name: "TestDataGrid",

  components: {
    DxButton,
  },

  methods: {
    sayHelloWorld() {
      alert("Hello world!");
    },
  },
};
</script>

<style></style>
