<template>
  <v-container fluid mx-2>
    <!--<div class="row">
      <div class="col-12 d-flex justify-end mb-6">
        <v-btn class="mr-6" color="primary" elevation="2" x-large
          >CONTROLLA NR CARTELLINI</v-btn
        >
        <v-btn color="primary" elevation="2" x-large
          >GENERA O TOGLI CARTELLINI</v-btn
        >
      </div>
    </div>-->

    <div class="row" style="margin-top:0px">
      <div class="col-12">
        <dx-data-grid
          ref="refGridRows"
          key="supplierCode"
          :show-borders="true"
          :data-source="data.rows"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :row-alternation-enabled="true"
          @row-removed="onDeleteRow"
          @row-updated="onUpdatedRow"
          @row-inserted="onRowInserted"
          @editor-preparing="onEditorPreparing"
        >
          <dx-editing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :select-text-on-edit-start="true"
            mode="cell"
          ></dx-editing>
          <dx-selection mode="single"></dx-selection>

          <dx-filter-row :visible="true" />

          <!--<dx-column data-field="supplierCod" caption="Art. Fornitore">
            <dx-lookup
              :data-source="articles"
              display-expr="descrizione"
              value-expr="codice"
            />
          </dx-column>-->

          <dx-column
            data-field="supplierCode"
            caption="Art. Fornitore"
            :set-cell-value="setCellValue"
          >
            <dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column
            data-field="supplierDescription"
            caption="Desc. Fornitore"
            :allow-editing="false"
          ></dx-column>

          <!--<dx-column type="buttons">
            <dx-button text="..." :on-click="myCommand"></dx-button>
          </dx-column>-->

          <dx-column data-field="kbUnits" caption="UM Kb">
            <dx-lookup
              :data-source="measures"
              display-expr="measureUnit"
              value-expr="measureUnit"
            />
            <dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column
            data-field="codeQty"
            caption="Qta"
            data-type="number"
            format="#,##0.00"
          >
            <dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column data-field="codeUnits" caption="UM codice">
            <dx-lookup
              :data-source="measures"
              display-expr="measureUnit"
              value-expr="measureUnit"
            />
            <dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column
            data-field="nrRedKanban"
            caption="Nr Rossi"
            data-type="number"
            format="#,##0"
          >
            <dx-required-rule></dx-required-rule
          ></dx-column>

          <dx-column
            data-field="nrYellowKanban"
            caption="Nr Gialli"
            data-type="number"
            format="#,##0"
            ><dx-required-rule></dx-required-rule
          ></dx-column>

          <dx-column
            data-field="nrGreenKanban"
            caption="Nr Verdi"
            data-type="number"
            format="#,##0"
            ><dx-required-rule></dx-required-rule
          ></dx-column>

          <dx-column
            data-field="nrGreyKanban"
            caption="Nr Grigi"
            data-type="number"
            format="#,##0"
            ><dx-required-rule></dx-required-rule
          ></dx-column>

          <dx-column data-field="idKanbanType" caption="Tipologia">
            <dx-lookup
              :data-source="kanbanTypes"
              display-expr="typeDescription"
              value-expr="idKanbanType"
            /><dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column
            data-field="nrKanbanControl"
            caption="Ctrl"
            data-type="number"
            format="#,##0"
            ><dx-required-rule></dx-required-rule
          ></dx-column>

          <dx-column
            data-field="nrLotKanban"
            caption="Nr Kb x Lotto"
            data-type="number"
            format="#,##0"
          >
            <dx-required-rule></dx-required-rule>
          </dx-column>

          <dx-column type="buttons">
            <dx-button name="delete">...</dx-button>
          </dx-column>
        </dx-data-grid>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

import {
  DxButton,
  DxDataGrid,
  DxColumn,
  //DxPaging,
  //DxFormat,
  DxSelection,
  DxEditing,
  DxLookup,
  DxFilterRow,
} from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";

export default {
  name: "EditKanbanData",

  components: {
    DxButton,
    DxDataGrid,
    DxColumn,
    //DxPaging,
    //DxFormat,
    DxSelection,
    DxEditing,
    DxRequiredRule,
    //DxAsyncRule,
    //DxCustomRule,
    DxLookup,
    DxFilterRow,
  },

  data: function() {
    return {
      data: { rows: [] },
      measures: [],
      kanbanTypes: [],
      articles: [],
    };
  },

  mounted: function() {
    const token = localStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    this.loadMeasures();
    this.loadKanbanTypes();
    this.loadArticles();
    this.loadData();
  },

  methods: {
    /*myCommand(e) {
      console.log(e.row.data);
    },*/

    async setCellValue(newData, value) {
      var ret = await this.validateArticle(value);
      if (ret == null) return false;

      console.log(ret);

      newData.supplierDescription = ret.descrizione;
      newData.supplierCode = ret.codice.toUpperCase();
    },

    async validateArticle(code) {
      try {
        this.showLoader(true);

        let ret = await axios({
          url: `/api/supplierKanban/validateArticle?code=${code}`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            return ret.data.data;
          } else {
            swal("ERROR", ret.data.errorMessage);
          }
        } else {
          swal("ERROR", ret.toString());
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    async onRowInserted(e) {
      var ret = await this.insertArticleData(e.data);
      if (!ret) {
        console.debug("inserted failed");
      }
    },

    async insertArticleData(row) {
      try {
        this.showLoader(true);

        row.supplierGuid = this.$route.params.divisionId;
        row.customerGuid = this.$route.params.divisionId;
        row.customerCode = row.supplierCode;

        let ret = await axios({
          url: `/api/supplierKanban/insertArticleData`,
          data: row,
          method: "POST",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            return true;
          } else {
            swal("ERROR", ret.data.errorMessage);
            return false;
          }
        } else {
          swal("ERROR", ret.toString());
          return false;
        }
      } catch (e) {
        swal("ERROR", e.toString());
        return false;
      } finally {
        this.showLoader(false);
      }
    },

    onUpdatedRow(e) {
      //console.log("Updated", e);

      this.updateArticleData(e.data);
    },

    async updateArticleData(row) {
      try {
        this.showLoader(true);

        let ret = await axios({
          url: `/api/supplierKanban/updateArticleData`,
          data: row,
          method: "POST",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            //do nothing
          } else {
            swal("ERROR", ret.data.errorMessage);
          }
        } else {
          swal("ERROR", ret.toString());
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    onEditorPreparing(e) {
      //Lascia attivo il codice solo se è una nuova riga
      if (
        e.row &&
        !e.row.isNewRow &&
        e.parentType === "dataRow" &&
        e.dataField === "supplierCode"
      ) {
        e.editorOptions.disabled = true;
      }

      //Evita che le frecce su e giù cambino il numero dentro alle caselle di tipo numerico
      if (e.editorName === "dxNumberBox") e.editorOptions.step = 0;
    },

    async onDeleteRow(e) {
      await this.deleteArticleData(e.data);
    },

    async loadData() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/getArticlesData?divisionGuid=${this.$route.params.divisionId}`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.data.rows = ret.data.data;
          }
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    async loadArticles() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/getArticles`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.articles = ret.data.data;
          } else {
            swal("ERROR", ret.data.errorMessage);
          }
        } else {
          swal("ERROR", ret.toString());
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    async loadMeasures() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/getMeasureUnits`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.measures = ret.data.data;
          }
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    async loadKanbanTypes() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/getKanbanTypes`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.kanbanTypes = ret.data.data;
          }
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    async deleteArticleData(row) {
      try {
        this.showLoader(true);

        let ret = await axios({
          url: `/api/supplierKanban/deleteArticleData?combinationGuid=${row.combinationGuid}`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            //do nothing
          } else {
            swal("ERROR", ret.data.errorMessage);
          }
        } else {
          swal("ERROR", ret.toString());
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    showLoader(visible) {
      this.$store.state.isWaiting = visible;
    },
  },
};
</script>

<style></style>
