<template>
    <div>
        Logout
    </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },

  created: function() {
    this.$store
      .dispatch("AUTH_LOGOUT", {})
      .then(() => {
        this.$router.push("/");
      })
      .catch(err => {
        console.debug(err);
      });
  }
};
</script>

<style>
</style>