<template>
  <v-container fluid>
    <div v-if="!data.CFEnable">
      <div style="height:20px"></div>
      <v-layout row>
        <v-flex xs12>
          <h2 style="text-align:center;color:#800000">{{$t("qrCode.kanbanCardNotFound")}}</h2>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="data.Status==0">
      <div style="height:20px"></div>
      <v-layout row>
        <v-flex xs12>
          <h2 style="text-align:center;color:#800000">{{$t("qrCode.kanbanCardDeleted")}}</h2>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="data.CFEnable && data.Status==1 && data.IdKanbanType!==5" style="text-align:center;">
      <v-layout row>
        <v-flex xs12 md6 offset-md3>
          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Supplier}}</h3>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Customer}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12 style="background-color:#c0c0c0;border:1px solid #333;margin:10px">
              <h2>{{data.Code}}</h2>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Description}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.KBUnits}} NR°</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.KanbanId}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:10px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.CodeUnits}}</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.CodeQty | formatNumber}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <v-btn
                color="success"
                block
                @click="setAsEmpty(data)"
              >{{$t('qrCode.kanbanButtonEmpty')}}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="data.CFEnable && data.Status==1 && data.IdKanbanType===5 && !data.customQty" style="text-align:center;">
      <v-layout row>
        <v-flex xs12 md6 offset-md3>
          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Supplier}}</h3>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Customer}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12 style="background-color:#c0c0c0;border:1px solid #333;margin:10px">
              <h2>{{data.Code}}</h2>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Description}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.KBUnits}} NR°</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.KanbanId}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:10px"></div>

          <v-layout row>
            <v-flex xs12>
              <v-text-field type="number" :label="data.CodeUnits" autofocus v-model="data.temp.customQty"></v-text-field>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <v-btn
                color="success"
                block
                @click="confirmCustomQty()"
              >CONFERMA QTA</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="data.CFEnable && data.Status==1 && data.IdKanbanType===5 && data.customQty" style="text-align:center;">
      <v-layout row>
        <v-flex xs12 md6 offset-md3>
          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Supplier}}</h3>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Customer}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12 style="background-color:#c0c0c0;border:1px solid #333;margin:10px">
              <h2>{{data.Code}}</h2>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Description}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.KBUnits}} NR°</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.KanbanId}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:10px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.CodeUnits}}</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.CodeQty | formatNumber}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <v-btn
                color="success"
                block
                @click="setAsEmpty(data)"
              >{{$t('qrCode.kanbanButtonEmpty')}}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <div v-if="data.CFEnable && data.Status==2" style="text-align:center;">
      <v-layout row>
        <v-flex xs12 md6 offset-md3>
          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Supplier}}</h3>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Customer}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12 style="background-color:#c0c0c0;border:1px solid #333;margin:10px">
              <h2>{{data.Code}}</h2>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <h3>{{data.Description}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.KBUnits}} NR°</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.KanbanId}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:10px"></div>

          <v-layout row>
            <v-flex xs6>
              <h3>{{data.CodeUnits}}</h3>
            </v-flex>
            <v-flex xs6>
              <h3>{{data.CodeQty | formatNumber}}</h3>
            </v-flex>
          </v-layout>

          <div style="height:20px"></div>

          <v-layout row>
            <v-flex xs12>
              <v-btn
                color="success"
                block
                @click="setAsFull(data)"
              >{{$t('qrCode.kanbanButtonLoad')}}</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
var numeral = require("numeral");
var moment = require("moment");
import { mapState } from "vuex";
import swal from "sweetalert";
import axios from "axios";

numeral.locale("it");

export default {
  name: "Home",

  props: {
    id: String,
    msg: String
  },

  data: function() {
    return {
      data: {}
    };
  },

  mounted: function() {
    const token = localStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    this.environment.ui.title = this.$t("qrCode.title");

    this.loadData();
  },

  computed: {
    ...mapState(["environment", "userAccount"])
  },

  methods: {
    confirmCustomQty(){
      this.data.CodeQty = Number(this.data.temp.customQty);
      this.data.customQty = this.data.temp.customQty;
    },

    loadData() {
      var self = this;

      axios({
        url: "/api/kanban/kanbanCard?id=" + this.$route.params.q,
        data: null,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success) {
            let obj = resp.data.data;
            obj.temp = {};
            obj.customQty = 0;
            obj.temp.customQty = null;

            self.data = obj;
          }
        })
        .catch(err => {
          console.debug(err);
        });
    },

    setAsEmpty(kanban) {
      swal({
        title: this.$t("messageBox.caution"),
        text: this.$t("qrCode.confirmKanbanEmpty"),
        icon: "warning",
        buttons: [this.$t("messageBox.cancel"), this.$t("messageBox.ok")],
        dangerMode: true
      }).then(confirm => {
        if (confirm) {
          let url = "/api/kanban/pickKanban?id=" + this.$route.params.q;
          if(kanban.customQty!=null){
            url += "&customQty=" + kanban.customQty;
          }
          axios({
            url: url,
            data: null,
            method: "GET"
          }).then(resp => {
              if (resp.data.success) {
                swal(this.$t("qrCode.confirmKanbanEmptied"), {
                  icon: "success"
                }).then(() => {
                  //location.reload();
                  document.location.href = "/";
                });
              }
            })
            .catch(err => {
              console.debug(err);
            });
        }
      });
    },

    setAsFull() {
      swal({
        title: this.$t("messageBox.caution"),
        text: this.$t("qrCode.confirmKanbanLoad"),
        icon: "warning",
        buttons: [this.$t("messageBox.cancel"), this.$t("messageBox.ok")],
        dangerMode: true
      }).then(confirm => {
        if (confirm) {
          axios({
            url: "/api/kanban/loadKanban?id=" + this.$route.params.q,
            data: null,
            method: "GET"
          })
            .then(resp => {
              if (resp.data.success) {
                swal(this.$t("qrCode.confirmKanbanLoaded"), {
                  icon: "success"
                }).then(() => {
                  //location.reload();
                  document.location.href = "/";
                });
              }
            })
            .catch(err => {
              console.debug(err);
            });
        }
      });
    }
  },

  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY hh:mm");
      }
    },

    formatNumber: function(value) {
      return numeral(value).format("0,0.[00]");
    }
  }
};
</script>

<style>
</style>
