<template>
  <v-container fluid>
      <v-layout row>
        <v-flex xs12>
            <div style="border:1px solid #000; width:500px; text-align:center; margin:0px auto">
                <v-layout row>
                    <v-flex xs12>
                        <img v-bind:src="'/qrcode/kanban/generator?id=' + this.$route.params.q" style="max-width:150px;border:1px solid #000;margin-top:10px">
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <h3>{{data.supplier}}</h3>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <h3>{{data.customer}}</h3>
                    </v-flex>
                </v-layout>

                <div style="height:25px"></div>

                <v-layout row>
                    <v-flex xs12>
                        <h2>{{data.customerCode}}</h2>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <h3>{{data.customerDescription}}</h3>
                    </v-flex>
                </v-layout>

                <div style="height:25px"></div>

                <v-layout row>
                    <v-flex xs6>
                        <h3>{{data.kbUnits}}</h3>
                    </v-flex>
                    <v-flex xs6>
                        <h3>{{data.kanbanId}}</h3>
                    </v-flex>
                </v-layout>
                
                <div style="height:10px"></div>
                
                <v-layout row>
                    <v-flex xs6>
                        <h3>{{data.codeUnits}}</h3>
                    </v-flex>
                    <v-flex xs6>
                        <h3>{{data.codeQty | formatNumber}}</h3>
                    </v-flex>
                </v-layout>

                <div style="height:25px"></div>
            </div>
        </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
var numeral = require("numeral");
var moment = require("moment");
import { mapState } from "vuex";
import axios from "axios";

numeral.locale('it');

export default {
  name: "Home",

    props: {
      id: String,
      msg: String
    },

  data: function() {
    return {
      data: {}
    };
  },

  mounted:function(){
    this.environment.ui.title=this.$t("kanbanLabel.title");

    this.lodaData();
  },

  computed: {
    ...mapState(["environment", "userAccount"])
  },

  methods: {
    lodaData() {
      var self = this;

      axios({ url: '/api/kanban/kanbanCardLabelData?id=' + this.$route.params.q, data: null, method: 'GET' })
          .then(resp => {
            if(resp.data.success){
              self.data = resp.data.data;
            }
          })
          .catch(err => {
            console.debug(err);
          })
    }
  },

  filters: {
    formatDate: function (value) {
        if (value) {
            return moment(value).format('DD/MM/YYYY hh:mm');
        }
    },

    formatNumber: function (value) {
        return numeral(value).format("0,0.[00]");
    }
  }
};
</script>

<style>
  
</style>
