import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

var moment = require("moment");

//Carica il token salvato nello storage locale
/*var token = localStorage.getItem('user-token');
if(token!==null){
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}*/

const store = new Vuex.Store({
  state: {
    userAccount: null,
    token: localStorage.getItem("user-token") || "",
    isWaiting: false,
    environment: {
      application: null,
      ui: {
        drawer: false,
        miniVariant: false,
        title: "E-Kanban",
        clipped: true,
      },
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
  },

  mutations: {},

  actions: {
    AUTH_REQUEST: ({ state }, user) => {
      return new Promise((resolve, reject) => {
        axios({ url: "/api/token/create", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem("user-token", token); // store the token in localstorage
            state.token = token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            // you have your token, now log in your user :)
            store.dispatch("USER_REQUEST");

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem("user-token"); // if the request fails, remove any possible user token if possible
            state.token = "";
            state.userAccount = null;
            reject(err);
          });
      });
    },

    AUTH_LOGOUT: ({ state }) => {
      return new Promise((resolve) => {
        localStorage.removeItem("user-token");
        state.userAccount = null;
        state.token = "";

        // remove the axios default header
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },

    USER_REQUEST: ({ state }) => {
      return new Promise((resolve, reject) => {
        axios({ url: "/api/token/user", data: null, method: "POST" })
          .then((resp) => {
            state.userAccount = resp.data;

            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem("user-token");
            state.userAccount = null;
            state.token = "";
            reject(err);
          });
      });
    },

    ENVIRONMENT_APPLICATION: ({ state }) => {
      return new Promise((resolve, reject) => {
        axios(
          { url: "/api/environment/application", data: null, method: "GET" },
        )
          .then((resp) => {
            state.environment.application = resp.data;

            //Aggiorna l'offset sulla data/ora del server
            var serverTime = moment(
              new Date(state.environment.application.serverDT),
            );
            state.environment.application.timeOffset = serverTime.diff(
              moment(),
            );

            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

export default store;
