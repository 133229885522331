<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>
        <div style="height:100px"></div>

        <v-layout row>
          <v-flex xs12>
            <img
              src="../../public/images/logo.gif"
              style="width:100%;border:1px solid #ddd;box-shadow: 10px 10px 5px grey;"
            />
          </v-flex>
        </v-layout>

        <div style="height:100px"></div>

      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Home",

  data: function() {
    return {
      someData: "some data..."
    };
  },

  mounted: function() {
    this.environment.ui.title = "E-Kanban";
  },

  computed: {
    ...mapState(["environment", "userAccount"])
  },

  props: {
    msg: String
  },

  methods: {
    
  }
};
</script>

<style>
</style>
