<template>
  <v-container fluid>
      <v-layout row>
        <v-flex xs12>
          <h1 style="margin-bottom:10px;text-align:center">{{$t('testReport.subtitle')}}</h1>
        </v-flex>
      </v-layout>

      <div style="height:50px"></div>

      <v-layout row>
        <v-flex xs6 offset-xs3>
          <v-text-field
              label="Search by Box ID" autofocus append-icon="search" @click:append="downloadReport()"  v-on:keyup.enter="downloadReport" v-model="boxId"
            ></v-text-field>
        </v-flex>
      </v-layout>

      <div style="height:50px"></div>

      <div v-if="data && data.length>0">
        <v-layout row>
          <v-flex xs6 offset-xs3>
              <v-layout row>
                <v-flex xs2>
                    <span style="font-weight:bold">DATE</span>
                </v-flex>
                <v-flex xs2>
                    <span style="font-weight:bold">MODEL</span>
                </v-flex>
                <v-flex xs2>
                    <span style="font-weight:bold">SIZE</span>
                </v-flex>
                <v-flex xs2>
                    <span style="font-weight:bold">BATCH NUM.</span>
                </v-flex>
              </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6 offset-xs3>
              <v-layout row>
                <v-flex xs2>
                    <span>{{data[0].dataTest | formatDate}}</span>
                </v-flex>
                <v-flex xs2>
                    <span>{{data[0].modello}}</span>
                </v-flex>
                <v-flex xs2>
                    <span>{{data[0].taglia}}</span>
                </v-flex>
                <v-flex xs2>
                    <span>{{data[0].lotto}}</span>
                </v-flex>
                <v-flex xs4>
                  <a v-bind:href="'/download/getTestReport?boxId=' + boxId" target="_blank" style="border:0px"><v-icon>cloud_done</v-icon></a>
                </v-flex>
              </v-layout>
          </v-flex>
        </v-layout>
      </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

var moment = require('moment');

export default {
  name: "SearchTestReport",

  data: function() {
    return {
      boxId: null,
      data: null
    };
  },

  mounted: function() {
    this.environment.ui.title = this.$t("testReport.title");
  },

  computed: {
    ...mapState(["environment", "userAccount"])
  },

  props: {
    msg: String
  },

  methods: {
    searchBox: function(){
      
    },

    downloadReport() {
      var self = this;
      self.data = null;

      axios({ url: '/api/reports/testReport/searchReport?boxid=' + self.boxId, data: null, method: 'GET' })
          .then(resp => {
            if(resp.data.success){
              self.data = resp.data.data;
            }
          })
          .catch(err => {
            console.debug(err);
          })
    },
    
    testGet: function() {
      axios
        .get("/api/values")
        .then(response => (this.someData = response.data));
    }
  },

  filters: {
        formatDate: function (value) {
            if (value) {
                return moment(value).format('DD/MM/YYYY');
            }
        }
    }
};
</script>

<style>
</style>
