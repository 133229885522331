<template>
  <v-container fluid mx-2>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="grey" dark>
          <v-toolbar-title>STAMPA ETICHETTE</v-toolbar-title>

          <v-spacer class="hidden-xs-only"></v-spacer>

          <v-icon
            @click="printAll()"
            class="mr-10"
            title="Esporta Excel cartellini"
            >fas fa-file-excel</v-icon
          >

          <v-icon
            @click="importCodes()"
            class="mr-10"
            title="Seleziona codici da lista"
            >fas fa-cloud-upload-alt</v-icon
          >

          <v-text-field
            light
            solo
            prepend-icon="search"
            placeholder="Cerca articolo..."
            v-model="searchText"
          ></v-text-field>
        </v-toolbar>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <v-checkbox @change="selectAllChange($event)"></v-checkbox>
                </th>
                <th>Cartellino</th>
                <th class="text-left">Codice</th>
                <th class="text-left">Descrizione</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in filteredKanbanItems" :key="i">
                <td>
                  <v-checkbox v-model="item.selected"></v-checkbox>
                </td>
                <td>{{ item.kanbanId }}</td>
                <td>{{ item.customerCode }}</td>
                <td>{{ item.customerDescription }}</td>
                <!-- <td>
                  <a
                    @click="showLabelModal(item)"
                    target="_blank"
                    style="border:0px;color:transparent"
                  >
                    <v-icon
                      style="width:20px;border:0px"
                      title="Stampa etichetta"
                      >printer</v-icon
                    >
                  </a>
                </td>
                <td>
                  <span style="margin-left:10px">
                    <v-btn
                      text
                      v-bind:href="
                        '/api/report/getPDFKanbanCardA5?kanbanGuid=' +
                          item.kanbanGuid
                      "
                      target="_blank"
                      >A5</v-btn
                    >
                  </span>
                </td>
                <td>
                  <span style="margin-left:10px">
                    <v-btn
                      text
                      v-bind:href="
                        '/api/report/getPDFKanbanCardA6?kanbanGuid=' +
                          item.kanbanGuid
                      "
                      target="_blank"
                      >A6</v-btn
                    >
                  </span>
                </td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-dialog v-model="kanbanLabel.showDialog" max-width="400">
          <v-card>
            <v-card-title class="headline"
              >Stampa etichetta Kanban</v-card-title
            >

            <v-card-text>
              Stampa l'etichetta Kanban per l'articolo
              <strong>
                {{
                  kanbanLabel.kanbanItem && kanbanLabel.kanbanItem.customerCode
                }} </strong
              >, contenitore
              <strong>
                {{ kanbanLabel.kanbanCard && kanbanLabel.kanbanCard.kanbanId }}
              </strong>
            </v-card-text>

            <v-card-text>
              <v-combobox
                v-model="kanbanLabel.currentPrinter"
                :items="printers"
                label="Scegli la stampante su cui stampare l'etichetta"
                item-text="key"
                item-value="value"
              ></v-combobox>
            </v-card-text>

            <v-card-text>
              <v-combobox
                v-model="kanbanLabel.copies"
                :items="copies"
                label="Numero di etichette da stampare"
              ></v-combobox>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" :text="true" @click="printLabel()"
                >Stampa etichetta</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="kanbanLabel.showDymoDialog" max-width="400">
          <v-card>
            <v-card-title class="headline"
              >Stampa etichetta Kanban Dymo</v-card-title
            >

            <v-card-text>
              Stampa l'etichetta Dymo - Kanban per l'articolo
              <strong>
                {{
                  kanbanLabel.kanbanItem && kanbanLabel.kanbanItem.customerCode
                }} </strong
              >, contenitore
              <strong>
                {{ kanbanLabel.kanbanCard && kanbanLabel.kanbanCard.kanbanId }}
              </strong>
            </v-card-text>

            <v-card-text>
              <v-combobox
                v-model="kanbanLabel.currentDymoPrinter"
                :items="dymoPrinters"
                label="Scegli la stampante Dymo su cui stampare l'etichetta"
                item-text="name"
                item-value="name"
              ></v-combobox>
            </v-card-text>

            <v-card-text>
              <v-combobox
                v-model="kanbanLabel.copies"
                :items="copies"
                label="Numero di etichette da stampare"
              ></v-combobox>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                :text="true"
                @click="printDymoLabel()"
                >Stampa etichetta Dymo</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="codesToImport.showDialog" max-width="600">
          <v-card>
            <v-card-title class="headline"
              >Importa codici da lista</v-card-title
            >

            <v-card-text
              >Incolla nella casella sottostante i codici da
              selezionare</v-card-text
            >

            <v-textarea
              solo
              v-model="codesToImport.list"
              label="Inserisci qui i codici"
            ></v-textarea>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" text @click="doImportCodes()"
                >IMPORTA</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
var numeral = require("numeral");
var moment = require("moment");
import { mapState } from "vuex";
import axios from "axios";
import swal from "sweetalert";

export default {
  name: "PrintLabels",

  props: {
    id: String,
    msg: String,
  },

  data: function() {
    return {
      data: [],

      kanbanLabel: {
        showDialog: false,
        showDymoDialog: false,
        kanbanItem: null,
        currentPrinter: null,
        currentDymoPrinter: null,
        copies: 1,
      },

      printers: [],
      dymoPrinters: [],

      copies: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

      searchText: "",

      codesToImport: {
        showDialog: false,
        label: "",
      },
    };
  },

  mounted: function() {
    const token = localStorage.getItem("user-token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.environment.ui.title = this.$t("supplierDashboard.title");

    this.loadLabelsData();
    this.loadPrintersAndLabels();

    //Ritardo il caricamento delle stampanti Dymo perchè è molto lento e mi blocca la pagina
    setTimeout(() => {
      this.loadDymoPrintersAndLabels();
    }, 2000);

    //Se c'è un testo di ricerca di default lo abilita
    if (this.$route.query.initialSearch) {
      this.searchText = this.$route.query.initialSearch;
    }
  },

  computed: {
    ...mapState(["environment", "userAccount"]),

    filteredKanbanItems: function() {
      if (!this.data) return null;

      if (!this.searchText || this.searchText.length == 0) return this.data;

      return this.data.filter((item) => {
        return item.customerCode
          .toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    },
  },

  methods: {
    importCodes() {
      this.codesToImport.list = "";
      this.codesToImport.showDialog = true;
    },

    doImportCodes() {
      this.codesToImport.showDialog = false;

      this.data.forEach((item) => {
        item.selected = false;
      });
      let result = this.codesToImport.list.split(/\r?\n/);
      result = result.map((s) => s.trim().toUpperCase());

      this.data.forEach((item) => {
        if (result.includes(item.customerCode)) {
          item.selected = true;
        }
      });

      this.data = [...this.data];
    },

    printAll() {
      const selected = this.data.filter((i) => {
        return i.selected;
      });

      const guids = selected.map((i) => {
        return i.kanbanGuid;
      });

      if (guids.length == 0) return;

      this.downloadExcel(guids);
    },

    async downloadExcel(guids) {
      try {
        this.showLoader(true);

        let ret = await axios.post(
          "/api/label/downloadXlsxCartelliniKanban",
          {
            kanbanGuids: guids,
          },
          {
            responseType: "blob",
          }
        );

        this.forceFileDownload(ret, `Cartellini.xlsx`);
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    forceFileDownload(response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    },

    selectAllChange(e) {
      if (e === true) {
        this.filteredKanbanItems.forEach((item) => {
          item.selected = true;
        });

        this.data = [...this.data];
      } else {
        this.data.forEach((item) => {
          item.selected = false;
        });

        this.data = [...this.data];
      }
    },

    /*moveToPrintPage() {
      this.$router
        .replace({
          name: "printLabels",
          params: { id: this.$route.params.id },
        })
        .catch((e) => {
          console.debug(e);
        });
    },*/

    async loadLabelsData() {
      try {
        this.showLoader(true);

        var self = this;

        let ret = await axios({
          url: `/api/supplierKanban/labelsData?supplierGuid=${this.$route.params.supplierId}&divisionGuid=${this.$route.params.divisionId}`,
          data: null,
          method: "GET",
        });

        if (ret.status == 200) {
          if (ret.data.success) {
            self.data = ret.data.data;

            self.data.forEach((item) => {
              item.selected = false;
            });
          }
        }
      } catch (e) {
        swal("ERROR", e.toString());
      } finally {
        this.showLoader(false);
      }
    },

    loadDymoPrintersAndLabels() {
      this.initDymo();
    },

    initDymo() {
      if (window.dymo.label.framework.init) {
        window.dymo.label.framework.init(this.onLoadDymo);
      } else {
        this.onLoadDymo();
      }
    },

    onLoadDymo() {
      let env = window.dymo.label.framework.checkEnvironment();

      if (
        !env.isBrowserSupported ||
        !env.isFrameworkInstalled ||
        !env.isWebServicePresent
      ) {
        return false;
      }

      let printers = window.dymo.label.framework.getPrinters();
      if (printers.length == 0) return false;
      this.dymoPrinters = printers;
    },

    async printDymoLabel() {
      if (this.kanbanLabel.kanbanItem === null) return;
      if (!this.kanbanLabel.copies) return;
      if (!this.kanbanLabel.currentDymoPrinter) return;

      let response = await fetch("/labels/dymo/kanban.label", {
        mode: "no-cors",
      });
      if (!response) {
        console.debug("cant open label file");
        return;
      }

      let xml = await response.text();

      //Sostituisce le variabili
      xml = xml.replace("${data}", moment().format("DD/MM/YYYY"));
      xml = xml.replace(
        "${CustomerCode}",
        this.kanbanLabel.kanbanItem.customerCode
      );
      xml = xml.replace(
        "${CustomerDescription}",
        this.kanbanLabel.kanbanItem.customerDescription
      );
      xml = xml.replace("${KanbanId}", this.kanbanLabel.kanbanItem.kanbanId);
      xml = xml.replace("${CodeQty}", this.kanbanLabel.kanbanItem.codeQty);
      xml = xml.replace(
        "${url}",
        `http://ekanban.syncro-system.com/qrcode/${this.kanbanLabel.kanbanItem.kanbanGuid}?code=${this.kanbanLabel.kanbanItem.customerCode}`
      );

      let label = window.dymo.label.framework.openLabelXml(xml);

      if (!label.isValidLabel()) {
        console.debug("label file not valid");
        return;
      }

      console.debug(`printing on ${this.kanbanLabel.currentDymoPrinter.name}`);
      label.print(this.kanbanLabel.currentDymoPrinter.name);
    },

    loadPrintersAndLabels() {
      let self = this;

      axios({
        url: "/api/label/GetLabelPrintersFolderList",
        data: null,
        method: "GET",
      })
        .then((resp) => {
          if (resp.data.success) {
            self.printers = resp.data.data;

            let printer = localStorage.getItem("default-printer");
            if (printer !== null) {
              let found = this.printers.find((p) => {
                return p.key === printer;
              });

              if (found) {
                this.kanbanLabel.currentPrinter = found;
              } else if (this.printers.length > 0) {
                this.kanbanLabel.currentPrinter = this.printers[0];
              }
            }
          } else {
            swal("ERROR", resp.data.errorMessage);
          }
        })
        .catch((e) => {
          swal("ERROR", e.toString());
        });
    },

    showLabelModal(kanbanItem) {
      if (this.dymoPrinters.length > 0) {
        if (this.kanbanLabel.currentDymoPrinter == null) {
          this.kanbanLabel.currentDymoPrinter = this.dymoPrinters[0];
        }

        this.kanbanLabel.kanbanItem = kanbanItem;
        this.kanbanLabel.copies = 1;
        this.kanbanLabel.showDymoDialog = true;
      } else {
        if (this.kanbanLabel.currentPrinter === null) {
          if (this.printers.length > 0) {
            this.kanbanLabel.currentPrinter = this.printers[0];
          }
        }

        this.kanbanLabel.kanbanItem = kanbanItem;
        this.kanbanLabel.copies = 1;
        this.kanbanLabel.showDialog = true;
      }
    },

    printLabel() {
      if (this.kanbanLabel.kanbanItem === null) return;
      if (!this.kanbanLabel.copies) return;
      if (!this.kanbanLabel.currentPrinter) return;

      localStorage.setItem(
        "default-printer",
        this.kanbanLabel.currentPrinter.key
      );

      var printData = {
        PrinterName: this.kanbanLabel.currentPrinter.key,
        LabelName: "kanban",
        Copies: this.kanbanLabel.copies,
      };

      printData.LabelVariables = new Object();
      printData.LabelVariables["DATA"] = moment().format("DD/MM/YYYY");
      printData.LabelVariables[
        "CustomerCode"
      ] = this.kanbanLabel.kanbanItem.customerCode;
      printData.LabelVariables[
        "CustomerDescription"
      ] = this.kanbanLabel.kanbanItem.customerDescription;
      printData.LabelVariables["KanbanId"] = String(
        this.kanbanLabel.kanbanItem.kanbanId
      );
      printData.LabelVariables["CodeQty"] = String(
        this.kanbanLabel.kanbanItem.codeQty
      );
      printData.LabelVariables[
        "url"
      ] = `http://ekanban.syncro-system.com/qrcode/${this.kanbanLabel.kanbanItem.kanbanGuid}?code=${this.kanbanLabel.kanbanItem.customerCode}`;
      printData.LabelVariables["KbUnits"] = this.kanbanLabel.kanbanItem.kbUnits;
      printData.LabelVariables[
        "PlantDivision"
      ] = this.kanbanLabel.kanbanItem.plantDivision;

      console.debug(printData.LabelVariables["url"]);

      axios({
        url: "/api/label/PrintArticleLabel",
        data: printData,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.kanbanLabel.showDialog = false;
          } else {
            swal("ERROR", resp.data.errorMessage);
          }
        })
        .catch((e) => {
          swal("ERROR", e.toString());
        });
    },

    showLoader(visible) {
      this.$store.state.isWaiting = visible;
    },
  },

  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YY HH:mm");
      }
    },

    formatNumber: function(value) {
      return numeral(value).format("0,0.[00]");
    },
  },
};
</script>

<style></style>
