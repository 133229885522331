<template>
    <div>
        <v-container>
            <v-layout>
                    <h2>{{$t("login.subtitle")}}</h2>
            </v-layout>

            <v-divider style="margin-bottom:20px"></v-divider>

            <v-layout row>
                <v-flex xs12>
                    <v-form v-model="valid" ref="form" v-on:submit.prevent="doLogin()">
                        <v-text-field
                            onfocus="this.select();"
                            v-model="username"
                            v-bind:label="$t('login.usernamePlaceholder')"
                            autofocus
                            required></v-text-field>
                        
                        <v-text-field
                            onfocus="this.select();"
                            ref="password"
                            v-model="password"
                            :type="'password'"
                            v-bind:label="$t('login.passwordPlaceholder')"
                            required></v-text-field>

                            <v-card-actions>
                                <v-btn type="submit" :disabled="!valid" color="success">
                                    {{$t("login.loginButton")}}
                                </v-btn>
                            </v-card-actions>
                    </v-form>
                </v-flex>
            </v-layout>

            <v-layout style="margin-top:20px">
                <v-flex>
                    <h4 style="color:darkred">{{errorMessage}}</h4>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: function() {
    return {
      username: "",
      password: "",
      valid: true,
      errorMessage: ""
    };
  },

  mounted: function() {
    this.environment.ui.title = this.$t("login.title");
  },

  computed: {
    ...mapState(["environment", "userAccount"])
  },

  methods: {
    doLogin() {
      let self = this;

      if (this.$refs.form.validate()) {
        self.$store
          .dispatch("AUTH_REQUEST", {
            username: this.username,
            password: this.password
          })
          .then(() => {
            this.$router.push("/");
          })
          .catch(err => {
            console.debug(err);
            self.errorMessage = "Nome utente o password non validi";
            self.$refs.password.focus();
          });
      }
    }
  }
};
</script>

<style>
</style>